import {assessmentsService} from "@/services/assessments.service";
import {isApiResponseValid} from "@/helpers/helpers";
import {AppError} from "@/services/errors";
import store from "@/store";
import {toRaw} from "vue";

export default {
    namespaced: true,
    state: {
        id: null,
        data: null,
        answers: [],
        isStarted: false,
        isFinished: false,
        isSendingData: false,
        responseData: null,
        isError: false,
        activeQuestion: null,
        activeQuestionIndex: null
    },

    getters: {
        getQuestions: (state) => {
            return state.data.questions;
        },

        getAnswers: (state) => {
            return state.answers;
        }
    },

    mutations: {
        SET_ID(state, data) {
            state.id = data
        },

        SET_DATA(state, data) {
            state.data = data
        },

        SET_RESPONSE_DATA(state, data) {
            state.responseData = data
        },

        SET_STARTED(state) {
            state.isStarted = true
        },

        SET_FINISHED(state) {
            state.isFinished = true
        },

        SET_SENDING_DATA(state, data) {
            state.isSendingData = data
        },

        SET_ERROR(state, data) {
            state.isError = data
        },

        ADD_ANSWER(state, data) {
            state.answers.push(data)
        },

        SET_ACTIVE_QUESTION(state, data) {
            state.activeQuestion = data;
        },

        SET_ACTIVE_INDEX(state, data) {
            state.activeQuestionIndex = data;
        },
    },

    actions: {
        async init({rootState, commit, dispatch}) {
            // get page id
            commit("SET_ID", rootState.page.pageId);

            try {
                const response = await assessmentsService.getById(rootState.page.pageId)

                if (!isApiResponseValid(response)) {
                    throw new AppError('Error loading data', {
                        "message": 'Chyba při načítání kurzů, zkuste obnovit stránku.',
                        'type': 'warning'
                    })
                }

                // save data from api
                commit("SET_DATA", response.data)

                // is completed
                if (response.data.date_finished) {
                    commit("SET_ERROR", 'completed')
                } else {
                    // has data
                    if (response.data.questions.length) {
                        dispatch("start")
                    } else {
                        commit("SET_ERROR", 'loading')
                    }
                }

            } catch (err) {
                commit("SET_ERROR", 'loading')
                if (err instanceof AppError && err.data) {
                    store.dispatch('app/addFlashMessage', err.data)
                } else {
                    throw err;
                }
            }
        },

        start({commit, getters}) {
            commit("SET_STARTED");

            const firstQuestion = getters.getQuestions[0]

            if (firstQuestion) {
                commit("SET_ACTIVE_QUESTION", firstQuestion)
                commit("SET_ACTIVE_INDEX", 0)
            } else {
                commit("SET_ERROR")
            }
        },

        nextQuestion({state, getters, commit, dispatch}) {
            // get last item from completed
            let nextQuestionIndex = state.activeQuestionIndex + 1;

            if (nextQuestionIndex === getters.getQuestions.length) {
                dispatch("evaluation")
            } else {
                // update index
                commit("SET_ACTIVE_INDEX", nextQuestionIndex)

                // get next question by index
                const nextQuestion = getters.getQuestions[nextQuestionIndex];

                // get dependency
                const dependsOnId = nextQuestion.depends_on_option_id;

                // check question dependency
                if (!dependsOnId) {
                    // no dependency => show question
                    commit("SET_ACTIVE_QUESTION", nextQuestion)
                } else {
                    // check if dependsOnId is in answers
                    const findDependencyInAnswers = state.answers.find(item => {
                        if (item.question.answer_type === 2) {
                            return item.answer.data.includes(dependsOnId);
                        } else if (item.question.answer_type === 4) {
                            return false;
                        } else {
                            return item.answer.data === dependsOnId;
                        }
                    });

                    // if question dependency is ok => show question
                    if (!!findDependencyInAnswers) {
                        commit("SET_ACTIVE_QUESTION", nextQuestion)
                    } else {
                        // get next question
                        dispatch('nextQuestion');
                    }
                }
            }
        },

        submitAnswer({dispatch, commit}, data) {
            commit("ADD_ANSWER", data);
            dispatch('nextQuestion')
        },

        async evaluation({commit, state, rootState}) {
            commit("SET_FINISHED", true)
            commit("SET_ERROR", false)

            // data for api request
            const answers = toRaw(state.answers).map(item => {
                let out = {
                    id: item.question.id,
                    answer: item.answer.data
                }

                if (item.answer.custom) {
                    out['custom'] = item.answer.custom;
                }

                return out;
            })

            try {
                commit('SET_SENDING_DATA', true);

                const response = await assessmentsService.submitAnswers(rootState.page.pageId, answers)

                commit("SET_RESPONSE_DATA", response.data)
                commit("SET_FINISHED", true)

            } catch (err) {
                commit("SET_ERROR", 'sending')
            } finally {
                commit('SET_SENDING_DATA', false);
            }

            console.log('evaluation', answers)

        },
    }
}